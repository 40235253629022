import axios from 'axios'

function getUserAccessListing(id) {
  return axios.get(`user-access/${id}`)
}

function updateUserRights(payload) {
  return axios.put('user-access', payload)
}

export default {
  getUserAccessListing,
  updateUserRights
}
