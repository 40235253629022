<template>
  <div>
    <!-- Field: Role -->
    <b-card no-body>
      <b-card-body>
        <b-col cols="12" md="4">
          <b-form-group label="User Role" label-for="user-role">
            <v-select
              v-model="role"
              class="role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleFilter"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
            />
          </b-form-group>
        </b-col>
      </b-card-body>
    </b-card>

    <!-- PERMISSION TABLE -->
    <b-card no-body class="border mt-1">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon icon="LockIcon" size="18" />
          <span class="align-middle ml-50">User Rights</span>
        </b-card-title>
      </b-card-header>
      <b-table
        v-if="!contentLoading"
        v-model="items"
        striped
        responsive
        class="mb-0"
        :items="items"
      >
        <template #cell(name)="data">
          {{ data.value }}
        </template>
        <template #cell(add)="data">
          <b-form-checkbox
            v-model="data.item.Add"
            :value="true"
            :unchecked-value="false"
          />
        </template>
        <template #cell(edit)="data">
          <b-form-checkbox
            v-model="data.item.Edit"
            :value="true"
            :unchecked-value="false"
          />
        </template>
        <template #cell(view)="data">
          <b-form-checkbox
            v-model="data.item.view"
            class="view"
            :value="true"
            :unchecked-value="false"
          />
        </template>
        <template #cell(delete)="data">
          <b-form-checkbox
            v-model="data.item.delete"
            class="delete"
            :value="true"
            :unchecked-value="false"
          />
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1 saveButton"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :disabled="loading"
      @click="updateUserRights"
    >
      Save Changes
      <div v-if="loading" class="spinner-border spinner-border-sm" />
    </b-button>
    <b-button variant="secondary" :to="{ name: 'dashboard' }">
      Cancel
    </b-button>
  </div>
</template>

<script>
import {
  BTable,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BButton,
  BFormGroup,
  BCardBody
} from "bootstrap-vue";
import vSelect from "vue-select";
import userAccessService from "@/services/user-rights/user-right.service";
import errorResponseHandler from "@/services/errorHandler";
import { roleFilter, RoleEnum, resConditionCheck } from "@/helpers/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import Loader from "@/layouts/skeloton-loader/Loader.vue";

export default {
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BButton,
    BCol,
    BFormGroup,
    BCardBody,
    vSelect,
    Loader
  },
  data() {
    return {
      contentLoading: true,
      roleFilter,
      role: RoleEnum.Admin,
      items: [],
      value: "",
      loading: false,
      RoleEnum,
      checkLoginRole
    };
  },

  watch: {
    role: {
      handler() {
        this.getUserAccessListing();
      }
    }
  },

  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    this.getUserAccessListing();
  },

  methods: {
    getUserAccessListing() {
      this.contentLoading = true
      const roleId = this.role;
      userAccessService
        .getUserAccessListing(roleId)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data.module;
            this.items = this.items.filter(
              item =>  item.name !== "User Admin"
                && item.name !== "Logs"
                && item.name !== "User-Rights"
            );
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    updateUserRights() {
      const updatedItems = JSON.parse(JSON.stringify(this.items));
      // const dashboard = {
      //   Add: true,
      //   Edit: true,
      //   delete: true,
      //   view: true,
      //   name: "Dashboard"
      // };
      const userRights = {
        Add: false,
        Edit: false,
        delete: false,
        view: false,
        name: "User-Rights"
      };
      // updatedItems.unshift(dashboard);
      updatedItems.unshift(userRights);
      //   this.items = this.items.filter(item => (item.name !== 'Dashboard'))
      const payload = {
        role_id: this.role,
        module: updatedItems
      };
      this.loading = true;

      userAccessService
        .updateUserRights(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.loading = false;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "CheckIcon",
                variant: "success"
              }
            });
          }
        })
        .catch(error => {
          this.loading = false;

          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger"
            }
          });
        });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
